import React, { useEffect, Suspense, lazy } from "react";
import Loading from "../../pages/loading";
import { useDispatch, useSelector } from "react-redux";

import {
  refreshWalkinData,
  updateRoomPriceStructure,
  updateSelectedRoomNumberForModify,
  updateTotal,
  updateRoomTotal,
  updateSubTotal,
  updateBalanceDue,
  updateDiscount,
  updateTaxIncludedInPrice,
  updateAdults,
  updateDetailedPriceBreakup,
  updateRoomStoredFromBookingCalendar,
  updateAddOnsTotalWithoutTax,
  updateAddOnsTax,
  updateTotalTax,
  updateDoa,
  updateDob,
  CLEAR_REDUX_STORE_46,
} from "../../reducer/walkinReducer";

import useRoomAvailabiltyLogic from "../../hooks/walkinCustomHook/useAvailabilyLogic";
import {
  addOnsCalculation,
  bookingPriceCalculation,
  mealPlanChangeCalculation,
  roomAssigneFunction,
} from "../../utils/bookingHelper";
import useGetMealPlanRate from "../../hooks/bookingCustomHook/useGetPlanRate";

const WalkinContainer = lazy(() =>
  import("../../components/walkinV3/WalkinContainer")
);
const MobileScreenWalkinContainer = lazy(() =>
  import(
    "../../mobileComponents/mobileScreenWalkin/MobileScreenWalkinContainer"
  )
);

const WalkinPageV3 = () => {
  const dispatch = useDispatch();

  const {
    roomPriceStructure,
    selectedRoomWTypeAndNum,
    mealPlan,
    nightsCount,
    discountInfo,
    checkIn,
    checkOut,
    paymentBreakUps,
    taxIncludedInPriceLocalState,

    adults,
    children,
    detailedPriceBreakup,

    roomsStoredFromBookingReservationCalendar,

    addOnsArray,
    tempSelectedRoomWTypeAndNum,
  } = useSelector((store) => store.walkin);

  const { taxResponse } = useSelector((store) => store.tax);
  const { isMobileScreen } = useSelector((store) => store.appHeader);
  const { hotelDetails, occupancyPlanRate } = useSelector(
    (store) => store.login
  );

  const roomTypes = hotelDetails?.roomTypes ? hotelDetails?.roomTypes : [];
  const taxIncludedInPrice = Boolean(hotelDetails?.taxIncludedInPrice);

  /* eslint-disable */
  useEffect(() => {
    const localRoomPriceStructure = roomTypes.map((room) => {
      let defaultOccupancy = Boolean(room?.defaultOccupancy)
        ? room?.defaultOccupancy
        : 2;

      // let rate = occupancyPlanRate?.[room?._id]?.[mealPlan]?.[defaultOccupancy];
      // rate = Boolean(rate) ? rate : room?.rate;
      let rate = room?.rate;

      return {
        name: room.name,
        rate: Boolean(rate) ? rate : 0,
      };
    });

    dispatch(updateRoomPriceStructure(localRoomPriceStructure));

    if (roomsStoredFromBookingReservationCalendar) {
      dispatch(updateRoomStoredFromBookingCalendar(false));

      const currentroomType = roomTypes?.find(
        (Obj) => Obj?.name === Object?.keys(tempSelectedRoomWTypeAndNum)?.[0]
      );
      dispatch(updateAdults(currentroomType?.defaultOccupancy ?? 1));
    } else dispatch(updateSelectedRoomNumberForModify({}));
  }, [
    hotelDetails?.id,
    checkIn,
    checkOut,
    roomTypes?.map((Obj) => Obj.rate).join(","),
  ]);

  useEffect(() => {
    if (Array.isArray(roomPriceStructure) && roomPriceStructure.length) {
      const updatedRoomPriceStructure = mealPlanChangeCalculation(
        roomPriceStructure,
        roomTypes,
        mealPlan,
        occupancyPlanRate
      );

      if (
        Array.isArray(updatedRoomPriceStructure) &&
        updatedRoomPriceStructure.length
      )
        dispatch(updateRoomPriceStructure(updatedRoomPriceStructure));
    }
  }, [mealPlan]);

  // Calculate Prices and Taxes
  useEffect(() => {
    if (roomPriceStructure.length && detailedPriceBreakup.length) {
      const roomPriceCalcResult = bookingPriceCalculation(
        selectedRoomWTypeAndNum,
        roomPriceStructure,
        roomTypes,
        nightsCount,
        discountInfo,
        paymentBreakUps,
        detailedPriceBreakup,
        taxIncludedInPriceLocalState,
        false,
        taxResponse
      );

      const addOnsCalcResult = addOnsCalculation(addOnsArray, taxResponse);

      dispatch(updateRoomTotal(roomPriceCalcResult?.roomTotal));
      dispatch(updateDiscount(roomPriceCalcResult?.discount));
      dispatch(updateSubTotal(roomPriceCalcResult?.subTotal));
      dispatch(
        updateTotalTax(
          roomPriceCalcResult?.totalRoomTax + addOnsCalcResult?.totalAddOnsTax
        )
      );
      dispatch(
        updateTotal(roomPriceCalcResult?.total + addOnsCalcResult?.addOnsTotal)
      );
      dispatch(
        updateBalanceDue(
          roomPriceCalcResult?.balanceLeft + +addOnsCalcResult?.addOnsTotal
        )
      );
      dispatch(
        updateAddOnsTotalWithoutTax(addOnsCalcResult?.addOnCostWithoutTax)
      );
      dispatch(updateAddOnsTax(addOnsCalcResult?.totalAddOnsTax));
    }
  }, [
    roomPriceStructure,
    selectedRoomWTypeAndNum,
    nightsCount,
    paymentBreakUps,
    discountInfo,
    taxIncludedInPriceLocalState,
    detailedPriceBreakup,
    addOnsArray,
    taxResponse,
  ]);

  useEffect(() => {
    dispatch(updateTaxIncludedInPrice(taxIncludedInPrice));
    applyDateWalkin(true);

    return () => {
      dispatch(refreshWalkinData());
      dispatch(CLEAR_REDUX_STORE_46());
    };
  }, [hotelDetails?.id]);

  useEffect(() => {
    roomAssigneFunction(
      adults,
      children,
      mealPlan,
      selectedRoomWTypeAndNum,
      roomTypes,
      roomPriceStructure,
      occupancyPlanRate,
      dispatch,
      updateAdults,
      updateDetailedPriceBreakup
    );
  }, [
    roomPriceStructure,
    selectedRoomWTypeAndNum,
    adults,
    children,
    occupancyPlanRate,
  ]);

  const applyDateWalkin = () => {
    dispatch(updateDoa(false));
    dispatch(updateDob(false));
  };

  useRoomAvailabiltyLogic();
  useGetMealPlanRate(roomPriceStructure, mealPlan);

  return (
    <Suspense fallback={<Loading />}>
      {isMobileScreen ? (
        <MobileScreenWalkinContainer />
      ) : (
        <div onClick={applyDateWalkin} className="bg-[#F2F4FC] h-[100%]">
          <WalkinContainer />
        </div>
      )}
    </Suspense>
  );
};

export default WalkinPageV3;
