import { useEffect, useState } from "react";
import { DASHBOARD_NAVIGATION_OPTIONS } from "../../../constants";
import NavigationItem from "../NavigationItem";
import { useSelector } from "react-redux";
import useScreen from "../../useHooks/useScreen";

const activeMenuWithHotelFilters = ["Dashboard", "Bookings", "Reports"];

const NavigationBarList = ({ dashboardSelectHandler, activePage, posName }) => {
  const [
    DASHBOARD_NAVIGATION_OPTIONS_MODIFIED,
    SET_DASHBOARD_NAVIGATION_OPTIONS_MODIFIED,
  ] = useState([]);

  const {
    viewBookingAllowed,
    viewClientAllowed,
    viewRatedAndAvailabiltyAllowed,
    editHouseKeepingAllowed,
    createPOSAllowed,
    currentUserPosIds,
    viewLedgerAllowed,
    viewPaymentAllowed,
    viewExpenseAllowed,
    viewInvoiceAllowed,
    isOwner,
    viewStocksAllowed,
  } = useSelector((store) => store.usersRestriction);

  const { isMobileScreen } = useSelector((store) => store.appHeader);
  const { isAllHotelsSelected } = useSelector((store) => store.login);
  const { activePosSlug } = useSelector((store) => store.posSetting);

  const { hotelDetails } = useSelector((store) => store.login);

  const screen = useScreen();

  useEffect(() => {
    const UPDATED_DASHBOARD_NAVIGATION_OPTIONS_MODIFIED =
      DASHBOARD_NAVIGATION_OPTIONS?.filter((option) => {
        let isAllowed = false;

        if (option?.title === "Bookings" && viewBookingAllowed) {
          option.link =
            hotelDetails?.bookingView === "calender"
              ? "bookingReservation"
              : "bookings";

          isAllowed = true;
        }

        if (
          option?.title === "Channel Manager" &&
          viewRatedAndAvailabiltyAllowed
        )
          isAllowed = true;
        if (option?.title === "Clients" && viewClientAllowed) isAllowed = true;
        if (option?.title === "Housekeeping" && editHouseKeepingAllowed)
          isAllowed = true;

        if (
          (option?.title === "POS" &&
            Array?.isArray(currentUserPosIds) &&
            currentUserPosIds?.length > 0) ||
          isOwner
        )
          isAllowed = true;

        if (option?.title === "Ledger" && viewLedgerAllowed) isAllowed = true;
        if (option?.title === "Payments" && viewPaymentAllowed)
          isAllowed = true;
        if (option?.title === "Expense" && viewExpenseAllowed) isAllowed = true;
        if (option?.title === "Invoice" && viewInvoiceAllowed) isAllowed = true;
        if (option?.title === "Manage Stocks" && viewStocksAllowed)
          isAllowed = true;

        if (
          (option?.title === "Expense" ||
            option?.title === "Invoice" ||
            option?.title === "Payments") &&
          isMobileScreen
        )
          isAllowed = false;

        if (option?.title === "Dashboard" || option?.title === "Reports")
          isAllowed = true;

        if (option?.title === "Booking Engine") isAllowed = true;

        return isAllowed;
      }).filter((option) => {
        return isAllHotelsSelected
          ? activeMenuWithHotelFilters.includes(option.title)
          : true;
      });

    SET_DASHBOARD_NAVIGATION_OPTIONS_MODIFIED(
      UPDATED_DASHBOARD_NAVIGATION_OPTIONS_MODIFIED
    );
  }, [
    DASHBOARD_NAVIGATION_OPTIONS,
    isAllHotelsSelected,
    createPOSAllowed,
    currentUserPosIds,
    isOwner,
    screen,
    hotelDetails,
  ]);

  return (
    <div className="flex flex-col pt-[65px]">
      {DASHBOARD_NAVIGATION_OPTIONS_MODIFIED.map((option) => {
        return (
          <NavigationItem
            key={option.id}
            navigationOptions={option}
            dashboardSelectHandler={dashboardSelectHandler}
            activePosSlug={activePosSlug}
          />
        );
      })}
    </div>
  );
};

export default NavigationBarList;
