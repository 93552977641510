import React, { useEffect, useState, useRef } from "react";
import { Button, message, Modal } from "antd";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Bill, TrashRedIcon } from "../../../Icon1";
import Card from "./itemsCard/Card";
import { BsArrowReturnRight } from "react-icons/bs";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  createTableOrder,
  getCustomerById,
  updateCustomerDetails,
  updateExcludeGST,
  resetCustomerDetails,
  updateDisplayAttachPartyDrawer,
  updateDisplayCloseOrderDrawer,
  updateDisplayTakePaymentDrawer,
  updateDisplayDiscountDrawer,
} from "../../../../reducer/posReducer/restaurant";
import {
  getAllPayments,
  updateEditPaymentDrawer,
} from "../../../../reducer/paymentReducer";
import {
  getCustomerByPhone,
  putCustomerById,
  saveCustomer,
} from "../../../../reducer/customerReducer";
import { CURRENCY_FORMAT, GET_ISO_FORMAT } from "../../../../utils/helper";
import {
  getBookingDetailsByItsIdAndRedirect,
  resetState,
  updateShowPaymentModeDropdown,
  updateOrderAndInvoice,
  updateIsCompanyInputFocused,
} from "../../../../reducer/orderReducer";
import { GoTrash } from "react-icons/go";

import {
  GET_ITEM_PRICE,
  ITEMS_TOTAL_AMOUNT,
  POS_TYPE,
} from "../../../../utils/posHelper";
import { FaChevronRight } from "react-icons/fa";

import {
  updateAllOrdersByStatus,
  updateInvoice,
} from "../../../../reducer/orderReducer";

import DeleteOrderModal from "./DeleteOrderModal";
import GenerateKOT from "./GenerateKOT";
import GenerateInvoice from "./GenerateInvoice";
import useDebounce from "../../../useHook/useDebounce";
import { CircularProgress, Tooltip } from "@mui/material";
import AttachRoomPartyDrawer from "./AttachRoomPartyDrawer";
import CloseOrderDrawer from "./CloseOrderDrawer";
import TakePaymentDrawer from "./TakePaymentDrawer";
import ApplyDiscountDrawer from "./DiscountDrawer";
import {
  updateActiveTab as updateBookingActiveTab,
  updateShowMainBookingDetailsDrawer,
} from "../../../../reducer/bookingReducer";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { ReactComponent as MobileUp } from "../../../../assets/icons/mobileUp.svg";
import { ReactComponent as LinkFile } from "../../../../assets/icons/linkFile.svg";
import { paymentModeTypes } from "../../../../utils/helper";
import {
  updateSearchText,
  resetInfiniteScrolling,
  getPartyList,
} from "../../../../reducer/partyReducer";
import { updateAddPartyDropdownVisible } from "../../../../reducer/expenseReducer";
import AddPartyDropdown from "../../../expense/dropdowns/AddPartyDropdown";
import { TranslateText } from "../../../../utils/translation";
import useAllPosFunctions from "../../../../hooks/posCustomHook/useAllPosFunctions";
import EditPaymentDrawer from "../../../common/components/UpdatePaymentDrawer/EditPaymentDrawer";
import { FaUndo } from "react-icons/fa";

function SideContainer() {
  const tableOrderDetails = useSelector((state) => state.pos.tableOrder);
  const [deletePopUp, setDeletePopUp] = useState(false);

  const dispatch = useDispatch();
  const [printKOT, setPrintKOT] = useState(false);
  const [printInvoice, setPrintInvoice] = useState(false);
  const [paymentModesExpanded, setPaymentModesExpanded] = useState(false);

  const { activePosType } = useSelector((store) => store.posSetting);

  const { allPayments } = useSelector((store) => store.payments);

  const { allRooms, selectedItem, loadingCustomerApi, customerDetails } =
    useSelector((store) => store.pos);

  const { data: allItems } = useSelector((store) => store.getItems);
  const [isServiceCharge, setIsServiceCharge] = useState(false);
  const { menuDrawer } = useSelector((store) => store.app);

  const {
    data: order,
    invoicePostData,
    invoicePostLoading,
    latestOrder,
    bookingDetails,
    isCompanyInputFocused,
    loading: orderLoading,
  } = useSelector((store) => store.order);

  const { hotelDetails } = useSelector((store) => store.login);
  const [numOfPeopleEdit, setNunOfPeopleEdit] = useState(false);
  const [isCustomerDetailsVisible, setIsCustomerDetailsVisible] =
    useState(false);
  const { partyResponse } = useSelector((store) => store.party);
  const [isCloseOrder, setIsCloseOrder] = useState(false);
  const [customer, setCustomer] = useState({});
  const [isAddOnVisible, setIsAddOnVisible] = useState({});

  const [deletePartyModal, setDeletePartyModal] = useState(false);

  const handleIsAddOnVisible = (id, value) =>
    setIsAddOnVisible({ ...isAddOnVisible, [id]: value });

  const handlePrint = (value) => setPrintKOT(value);

  const handleInvoicePrint = (value) => setPrintInvoice(value);
  const componentRef = useRef(null);
  const componentInvoiceRef = useRef(null);

  let customerId = "";
  order?.forEach((item) => {
    if (!customerId && Boolean(item?.customer)) customerId = item?.customer;
  });

  const { searchText } = useSelector((store) => store.party);
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      hotelId: hotelDetails?.id,
      invoiceId: invoicePostData?.id,
      excludePaymentMethods: [""],
    };

    if (invoicePostData?.id) dispatch(getAllPayments(payload));
  }, [invoicePostData]);

  useEffect(() => {
    if (Boolean(customerId)) {
      dispatch(
        getCustomerById({
          id: customerId,
          hotelId: hotelDetails?.id,
          invoiceId: invoicePostData?.id,
        })
      );
    }

    setIsCustomerDetailsVisible(false);
  }, [customerId, selectedItem]);

  useEffect(() => {
    const payload = {
      hotelId: hotelDetails?.id,
      pageSize: 100,
      searchText: searchText,
      infiniteScrollDisabled: true,
      partyTypes: "source,vendor",
    };
    dispatch(getPartyList(payload));
  }, [searchText]);

  useEffect(() => {
    let orderPayload = order?.length ? { ...order[0] } : {};
    if (
      Object.keys(orderPayload)?.length &&
      (orderPayload?.customer || orderPayload?.customer == null) &&
      customerDetails?.id &&
      orderPayload?.customer !== customerDetails?.id
    ) {
      orderPayload = {
        ...orderPayload,
        customer: customerDetails?.id,
      };
      let newPayload = {
        orderPayload: orderPayload,
        invoicePayload: { ...invoicePostData, customerId: customerDetails?.id },
        messageDisabled: true,
      };
      dispatch(updateOrderAndInvoice(newPayload));
    }
  }, [customerDetails]);

  useEffect(() => {
    let orderPayload = order?.length ? { ...order[0] } : {};
    if (
      Object.keys(orderPayload)?.length &&
      (orderPayload?.customer || orderPayload?.customer == null) &&
      customerDetails?.id &&
      orderPayload?.customer !== customerDetails?.id
    ) {
      orderPayload = {
        ...orderPayload,
        customer: customerDetails?.id,
      };
      let newPayload = {
        orderPayload: orderPayload,
        invoicePayload: null,
        messageDisabled: true,
      };
      dispatch(updateOrderAndInvoice(newPayload));
    }
  }, [customerDetails]);

  useEffect(() => {
    if (tableOrderDetails?.reservation) getReserveCustomer();

    if (hotelDetails?.id && tableOrderDetails?.customerNumber) {
      dispatch(
        getCustomerByPhone({
          hotelId: hotelDetails?.id,
          phone: tableOrderDetails?.customerNumber,
        })
      );
    }

    setCustomer(customerDetails);
  }, []);

  const getReserveCustomer = async () => {
    if (hotelDetails?.id && tableOrderDetails?.customerNumber) {
      let response = await dispatch(
        getCustomerByPhone({
          hotelId: hotelDetails?.id,
          phone: tableOrderDetails?.customerNumber,
        })
      );
      if (response?.payload) setCustomer(response?.payload);
    }
  };

  useEffect(() => {
    printPDF();
  }, [latestOrder?.id]);

  useEffect(() => {
    // update order table with customer name
    if (Object?.keys(customerDetails)?.length > 0) {
      setCustomer({
        ...customer,
        ...customerDetails,
      });
    }
  }, [customerDetails]);

  const handleNumOfPersonBlue = () => {
    if (!Boolean(tableOrderDetails?.numberOfPeople)) {
      const payload = { ...tableOrderDetails, numberOfPeople: 1 };
      dispatch(createTableOrder(payload));
    }
    setNunOfPeopleEdit(false);
  };

  const handleNumOfPersonChange = (value) => {
    const payload = { ...tableOrderDetails, numberOfPeople: value };
    dispatch(createTableOrder(payload));
  };

  const handleCustomerDetails = async (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;

    setCustomer({ ...customer, [fieldName]: fieldValue });
  };

  // Debounce function for optimizing api call when onchange apply
  const handleCustomeByphoneDebounced = useDebounce(async (obj) => {
    if (obj?.phone) {
      let customerDetails = await dispatch(
        getCustomerByPhone({
          hotelId: hotelDetails?.id,
          phone: obj?.phone,
          countryCode: obj?.countryCode,
        })
      );

      // store customer details into pos customer
      if (Boolean(customerDetails?.payload?.name)) {
        dispatch(updateCustomerDetails(customerDetails?.payload));
        setIsCustomerDetailsVisible(false);
      } else {
        dispatch(updateCustomerDetails(customerDetails?.payload));
      }
    }
  }, 500);

  const submitCustomerDetails = async (e) => {
    e.preventDefault();

    let payload = {
      ...customer,
      dob: Boolean(customer.dob) ? GET_ISO_FORMAT(customer.dob) : null,
      anniversary: Boolean(customer.anniversary)
        ? GET_ISO_FORMAT(customer.doanniversaryb)
        : null,
      hotelId: hotelDetails.id,
    };

    if (customerDetails?.name) {
      // customer exist, update customer

      let customerUpdatedDetails = await dispatch(
        putCustomerById({ ...customer, invoiceIds: invoicePostData?.id })
      );

      if (Boolean(customerUpdatedDetails?.payload)) {
        dispatch(updateCustomerDetails(customerUpdatedDetails?.payload));
        message.success("Customer details successfully updated");
      }
    } else {
      // create customer
      let customer = await dispatch(
        saveCustomer({
          ...payload,
          phone: payload.phoneNumber,
          contactDetails: {
            countryCode: payload.countryCode,
            phoneNumber: payload.phoneNumber,
          },
        })
      );
      if (Boolean(customer?.payload)) {
        // making API call to put the response with invoiceId as params, as per Satyam's convention

        if (invoicePostData?.id) {
          await dispatch(
            putCustomerById({
              ...customer?.payload,
              invoiceIds: invoicePostData?.id,
            })
          );
        }

        dispatch(updateCustomerDetails(customer?.payload));
        message.success("Customer details successfully saved");
      }
    }

    setIsCustomerDetailsVisible(false);
  };

  // seleceted food item with quantity
  let selectedItemWithQuantity = [];
  let itemCountMap = {};
  let itemTotalAmount = 0;

  Object.keys(selectedItem)?.forEach((id) => {
    allItems?.forEach((item) => {
      if (item.id === id && selectedItem[id].count !== 0) {
        selectedItemWithQuantity.push({
          ...item,
          quantity: selectedItem[id].count,
        });

        itemTotalAmount +=
          GET_ITEM_PRICE(tableOrderDetails.type, activePosType, item) *
          selectedItem[id].count;

        itemCountMap[id] = {
          count: selectedItem[id].count,
          instruction: Boolean(selectedItem[id].instruction)
            ? selectedItem[id].instruction
            : null,
        };

        // calculate item addon price
        item.addOns?.forEach((addonItem) => {
          if (Boolean(selectedItem[id][addonItem.name])) {
            //  price * qty
            itemTotalAmount +=
              addonItem.price * selectedItem[id][addonItem.name];

            itemCountMap[id] = {
              ...itemCountMap[id],
              addOns: {
                ...itemCountMap?.addOns,
                [addonItem?.name]: selectedItem[id][addonItem.name],
              },
            };
          }
        });
      }
    });
  });

  const printPDF = useReactToPrint({
    onAfterPrint: () => handlePrint(false),
    content: () => componentRef.current,
    documentTitle: "Kot",
  });

  const printInvoicePDF = useReactToPrint({
    onAfterPrint: () => handleInvoicePrint(false),
    content: () => componentInvoiceRef.current,
    documentTitle: "Invoice",
  });

  const {
    createBill,
    handleKOT,
    handleServiceCharge,
    deletePartyAndRoomFromPosInvoice,
    handleUndoBilled,
  } = useAllPosFunctions();

  const { loggedInUserPermissions, editPaymentAllowed, deletePaymentAllowed } =
    useSelector((store) => store.usersRestriction);
  let isOwner = loggedInUserPermissions?.includes("OWNER") ?? false;

  const {
    status,
    cgst,
    sgst,
    discount,
    subTotal,
    total,
    paid,
    serviceCharge,
    priceBreakup,
    discountInfo,
  } = invoicePostData || {};

  let itemPrice = ITEMS_TOTAL_AMOUNT(priceBreakup);
  const { type, value } = discountInfo || {};

  const [serviceChargeAmount, setServiceChargeAmount] = useState(serviceCharge);

  useEffect(() => {
    setServiceChargeAmount(serviceCharge);
  }, [type, value, serviceCharge]);

  useEffect(() => {
    let isExcludeGst = Boolean(+cgst) && Boolean(+sgst) ? true : false;
    dispatch(updateExcludeGST(isExcludeGst));
    return () => {
      dispatch(updateExcludeGST(true));
      dispatch(resetCustomerDetails());
      dispatch(updateShowPaymentModeDropdown(false));
    };
  }, [invoicePostData?.id]);

  let partyName = Array.isArray(partyResponse)
    ? partyResponse
        ?.filter((Obj) => Obj?.id === invoicePostData?.partyId)
        ?.map((Obj) => Obj?.displayName)?.[0]
    : "";

  let roomName = Array.isArray(allRooms)
    ? allRooms
        ?.filter((Obj) => Obj?.id === invoicePostData?.roomId)
        ?.map((Obj) => Obj?.roomNum)?.[0]
    : "";

  const handleAttahedCloseOrder = async () => {
    const payload = {
      ...invoicePostData,
      status: "due",
      hotelId: hotelDetails?.id,
      navigate: navigate,
      navigateTo: "dashboard/pos/table-view",
    };

    dispatch(
      updateAllOrdersByStatus({
        runningOrders: order,
        status: "completed",
      })
    );

    const response = await dispatch(updateInvoice(payload));
    if (response?.payload?.id) {
      message.success("Order closed successfully");
    }
  };

  const handleCloseOrderDueZero = async () => {
    const payload = {
      ...invoicePostData,
      status: "paid",
      hotelId: hotelDetails?.id,
      navigate: navigate,
      navigateTo: "dashboard/pos/table-view",
    };

    dispatch(
      updateAllOrdersByStatus({
        runningOrders: order,
        status: "completed",
      })
    );

    const response = await dispatch(updateInvoice(payload));
    if (response?.payload?.id) message.success("Order closed successfully");
  };

  const handleBookingRedirection = async (roomNumber, data, e) => {
    const payload = {
      id: data?.bookingId,
      hotelId: data?.hotelId,
    };
    await dispatch(getBookingDetailsByItsIdAndRedirect(payload));
    e.stopPropagation();
  };

  useEffect(() => {
    // This useEffect will be called after handleBookingRedirection is called successfully
    if (bookingDetails?.id) {
      const status = bookingDetails?.status;
      let statusEnum = "";

      if (status === "checkedIn") statusEnum = "ongoing";
      else statusEnum = "completed";

      dispatch(updateBookingActiveTab(statusEnum));
      navigate("/dashboard/bookings");

      dispatch(
        updateShowMainBookingDetailsDrawer({
          visible: true,
          bookingId: bookingDetails?.id,
          bookingType: statusEnum,
          newBookingId: bookingDetails?.bookingId,
        })
      );
      dispatch(resetState());
    }
  }, [bookingDetails]);

  const resetSourceNameFields = () => {
    dispatch(resetInfiniteScrolling("resetPageNumber"));
    dispatch(updateSearchText(""));
    setCustomer((prevCustomer) => ({ ...prevCustomer, company: "" }));
  };

  let currentTaxObject = invoicePostData?.taxObjects || [];

  return (
    <div
      className="relative h-[94vh]"
      onClick={() => {
        dispatch(updateIsCompanyInputFocused(false));
        dispatch(resetInfiniteScrolling("resetPageNumber"));
        dispatch(updateSearchText(""));
      }}
    >
      <div className="flex justify-between items-center border-b px-3 pb-2 pt-8 shadow-md h-[7rem]">
        <div className="flex flex-col">
          {!(
            tableOrderDetails?.type === "delivery" ||
            tableOrderDetails?.type === "pickup" ||
            activePosType == POS_TYPE[1] ||
            activePosType == POS_TYPE[3] ||
            activePosType == POS_TYPE[4] ||
            activePosType == POS_TYPE[5]
          ) && (
            <div>
              <div className="flex">
                <div className="text-[#000000B2] text-[20px] font-semibold">
                  {tableOrderDetails?.type === "table" ? (
                    <Button>{`Table ${tableOrderDetails?.tableNumber}`}</Button>
                  ) : tableOrderDetails?.type === "room" ? (
                    <Button>{`Room ${tableOrderDetails?.tableNumber}`}</Button>
                  ) : (
                    <Button>{tableOrderDetails?.tableNumber}</Button>
                  )}

                  {" - "}
                </div>

                <div className="text-[#00000080] text-[20px] font-normal flex">
                  <input
                    type={numOfPeopleEdit ? "number" : "text"}
                    onFocus={() => setNunOfPeopleEdit(true)}
                    onChange={(e) => handleNumOfPersonChange(e.target.value)}
                    onBlur={handleNumOfPersonBlue}
                    value={
                      numOfPeopleEdit
                        ? tableOrderDetails.numberOfPeople
                        : tableOrderDetails.numberOfPeople + " Person"
                    }
                    className={`${
                      numOfPeopleEdit ? " w-[90px]" : " w-[100px]"
                    } px-3 py-1 ml-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block  rounded-md sm:text-sm focus:ring-1`}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-between w-[100%]">
            <div
              onClick={() =>
                setIsCustomerDetailsVisible(!isCustomerDetailsVisible)
              }
              className="cursor-pointer flex items-center gap-1 text[16px] font-normal mb-1 mt-2 text-[#000000B2] mr-2"
            >
              <p>
                {" "}
                {Boolean(customer?.name) ? (
                  customer?.name
                ) : Boolean(tableOrderDetails?.name) ? (
                  tableOrderDetails?.name
                ) : (
                  <TranslateText
                    textKey={"enter_customer_name"}
                    namespace="pos"
                  />
                )}
              </p>

              {/* {tableOrderDetails?.stewardname} */}
              {isCustomerDetailsVisible ? (
                <BiSolidDownArrow
                  onClick={() =>
                    setIsCustomerDetailsVisible(!isCustomerDetailsVisible)
                  }
                  style={{ color: "#2F80ED" }}
                />
              ) : (
                <BiSolidUpArrow
                  onClick={() =>
                    setIsCustomerDetailsVisible(!isCustomerDetailsVisible)
                  }
                  style={{ color: "#2F80ED" }}
                />
              )}
            </div>

            {(tableOrderDetails?.type === "delivery" ||
              tableOrderDetails?.type === "pickup") && (
              <div className="text-[#00000080] text-[20px] font-normal flex">
                <input
                  type={numOfPeopleEdit ? "number" : "text"}
                  onFocus={() => setNunOfPeopleEdit(true)}
                  onChange={(e) => handleNumOfPersonChange(e.target.value)}
                  onBlur={handleNumOfPersonBlue}
                  value={
                    numOfPeopleEdit
                      ? tableOrderDetails.numberOfPeople
                      : tableOrderDetails.numberOfPeople + " Person"
                  }
                  className={`${
                    numOfPeopleEdit ? " w-[90px]" : " w-[100px]"
                  } px-3 py-1 ml-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block  rounded-md sm:text-sm focus:ring-1`}
                />
              </div>
            )}
          </div>
        </div>

        {order?.length > 0 && isOwner && (
          <div
            onClick={() => setDeletePopUp(true)}
            className="flex flex-col items-center gap-1 cursor-pointer"
          >
            <span>
              <TrashRedIcon />
            </span>
            <span className="text-[10px] text-[#00000080]">
              <TranslateText textKey={"delete"} namespace="pos" />
            </span>
          </div>
        )}
      </div>

      {isCustomerDetailsVisible && (
        <div className="shadow-md bg-[#EAF2FD] p-5 absolute  right-0 left-0 z-10">
          <div>
            <h1 className="text-[14px] mb-2 ml-2">
              <TranslateText textKey={"add_customer_details"} namespace="pos" />
            </h1>
          </div>

          <form onSubmit={submitCustomerDetails}>
            <div className="grid grid-cols-1 pl-2 w-[100%] mb-4">
              <PhoneInput
                defaultCountry="in"
                value={
                  Boolean(
                    customer?.["contactDetails"]?.["countryCode"] +
                      customer?.["contactDetails"]?.["phoneNumber"]
                  )
                    ? customer?.["contactDetails"]?.["countryCode"] +
                      customer?.["contactDetails"]?.["phoneNumber"]
                    : ""
                }
                onChange={(value, country) => {
                  const parts = country.inputValue.split(" ");
                  let countryCode = parts?.[0];

                  const joinedString = parts
                    ?.slice(1)
                    ?.map((str) => str?.replace(/\D/g, "")) // Remove non-numeric characters
                    ?.join("");

                  setCustomer({
                    ...customer,
                    phoneNumber: joinedString,
                    countryCode: countryCode,
                    phoneValue: value,
                  });

                  handleCustomeByphoneDebounced({
                    phone: joinedString,
                    countryCode: countryCode,
                  });
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="text-xs mb-1 ml-2">
                  <TranslateText textKey={"name"} namespace="pos" />
                </label>
                <input
                  name="name"
                  value={Boolean(customer["name"]) ? customer["name"] : ""}
                  required
                  type="text"
                  className={`w-full px-3 py-2 ml-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block  rounded-md sm:text-sm focus:ring-1`}
                  placeholder="Name"
                  onChange={(e) => handleCustomerDetails(e)}
                />
              </div>

              <div>
                <label className="text-xs mb-1 ml-2">
                  <TranslateText textKey={"name"} namespace="pos" />
                </label>
                <input
                  type="date"
                  name="dob"
                  // value="2017-06-01"
                  value={moment(
                    Boolean(customer["dob"]) ? customer["dob"] : ""
                  ).format("YYYY-MM-DD")}
                  onChange={(e) => handleCustomerDetails(e)}
                  className={`w-full px-3 py-2 ml-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block  rounded-md sm:text-sm focus:ring-1`}
                  placeholder="dd/mm/yy"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="text-xs mb-1 ml-2">
                  <TranslateText textKey={"company_name"} namespace="pos" />
                </label>
                <input
                  name="company"
                  value={
                    Boolean(customer["company"])
                      ? customer["company"]
                      : searchText
                  }
                  type="text"
                  className={`w-full px-3 py-2 ml-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block  rounded-md sm:text-sm focus:ring-1`}
                  placeholder="Company Name"
                  // onChange={(e) => handleCustomerDetails(e)}
                  onClick={(e) => {
                    dispatch(updateIsCompanyInputFocused(true));
                    dispatch(resetInfiniteScrolling("resetPageNumber"));
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    dispatch(updateSearchText(e.target.value));
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 8 && customer["company"]) {
                      resetSourceNameFields();
                    }
                  }}
                />
                <div
                  className={`w-[48%] h-[200px] overflow-y-scroll bg-[#fff] z-[99999] border-[#cfcfcf] shadow-md absolute rounded-[10px] ${
                    !isCompanyInputFocused && "invisible"
                  }`}
                >
                  <div
                    className="w-full h-[38px] bg-[#fff] flex items-center justify-start pl-[10px] cursor-pointer border-b-[1px] border-b-[#f2f2f2]"
                    onClick={(e) => {
                      dispatch(updateAddPartyDropdownVisible(true));
                      dispatch(updateIsCompanyInputFocused(false));
                      resetSourceNameFields();
                      e.stopPropagation();
                    }}
                  >
                    <span className="text-[13px] leading-[18px] text-[#007fff]">
                      <TranslateText textKey={"add_company"} namespace="pos" />
                    </span>
                  </div>
                  {partyResponse?.map((party) => {
                    const { id, displayName } = party;
                    return (
                      <div
                        key={id}
                        className="w-full h-[38px] bg-[#fff] flex items-center justify-start pl-[10px] border-b-[1px] border-b-[#f2f2f2] cursor-pointer"
                        onClick={(e) => {
                          setCustomer({
                            ...customer,
                            company: displayName,
                          });

                          dispatch(updateSearchText(""));

                          dispatch(updateIsCompanyInputFocused(false));
                          e.stopPropagation();
                        }}
                      >
                        <span className="text-[13px] leading-[18px] text-[#00000098]">
                          {displayName}
                        </span>
                      </div>
                    );
                  })}

                  <div className="w-full h-[38px] bg-[#fff] flex items-center justify-center pl-[10px] border-b-[1px] border-b-[#f2f2f2]">
                    <span className="text-[13px] leading-[18px] text-[#00000098]">
                      <TranslateText
                        textKey={"end_of_the_list"}
                        namespace="pos"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <label className="text-xs mb-1 ml-2">
                  <TranslateText textKey={"gstin"} namespace="pos" />
                </label>
                <input
                  name="gstIN"
                  value={Boolean(customer["gstIN"]) ? customer["gstIN"] : ""}
                  type="text"
                  className={`w-full px-3 py-2 ml-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block  rounded-md sm:text-sm focus:ring-1`}
                  placeholder="GSTIN"
                  onChange={(e) => handleCustomerDetails(e)}
                />
              </div>
              <AddPartyDropdown
                inPos={true}
                partyType="source"
                setCustomer={setCustomer}
                customer={customer}
              />
            </div>

            <div className="grid grid-cols-1  mb-4">
              <label className="text-xs mb-1 ml-2">
                <TranslateText textKey={"address"} namespace="pos" />
              </label>
              <input
                name="address"
                value={Boolean(customer["address"]) ? customer["address"] : ""}
                type="text"
                className={`w-full px-3 py-2 ml-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block  rounded-md sm:text-sm focus:ring-1`}
                placeholder="Address"
                onChange={(e) => handleCustomerDetails(e)}
              />
            </div>

            {loadingCustomerApi ? (
              <input
                disabled={true}
                type="submit"
                value="loading..."
                className="w-full bg-blue-400 h-10 ml-2 rounded-md text-white cursor-pointer hover:bg-blue-500"
              />
            ) : (
              <input
                type="submit"
                value="Save Details"
                className="w-full bg-blue-400 h-10 ml-2 rounded-md text-white cursor-pointer hover:bg-blue-500"
              />
            )}
          </form>
        </div>
      )}

      {/*  */}
      <div className="flex pt-4">
        <div
          className={`${
            status == "paid" || status == "billed" ? "h-[48vh]" : "h-[70vh]"
          }  w-full flex-1 gap-2  overflow-auto `}
        >
          {status === "billed" || status === "paid" ? (
            <div className="px-2 h-[21rem] pb-[6rem] overflow-auto">
              {priceBreakup?.map((item) => {
                return (
                  <div className="bg-white rounded p-3 mb-2 ">
                    <div className="flex justify-between px-2">
                      <p className="text-sm text-[#000000B2]">
                        {item.type}{" "}
                        <span className="text-xs">
                          {" "}
                          ({item.rate}X{item.count})
                        </span>{" "}
                      </p>
                      <p className="font-semibold text-sm text-[#00000080] ">
                        {CURRENCY_FORMAT(+item.rate * +item.count)}
                      </p>
                    </div>

                    {item.addOns?.map((addOn) => {
                      return (
                        <div className="flex justify-between px-2 mt-0">
                          <div className="flex">
                            <span className="mt-1">
                              <BsArrowReturnRight />
                            </span>
                            <span className="text-[14px] text-[#000000B2] ml-2 mr-2 ">
                              {addOn.name}

                              <span className="text-xs">
                                {" "}
                                ({addOn.price}X{addOn.count})
                              </span>
                            </span>
                          </div>

                          <div>
                            <div className="flex flex-col gap-1">
                              <span className="text-[14px] font-medium text-[#000000B2]">
                                {CURRENCY_FORMAT(+addOn.price * +addOn.count)}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-5">
              {selectedItemWithQuantity?.map((item) => (
                <Card
                  key={item.id}
                  item={item}
                  handleIsAddOnVisible={(id, value) =>
                    handleIsAddOnVisible(id, value)
                  }
                  isAddOnVisible={isAddOnVisible}
                />
              ))}
            </div>
          )}
        </div>

        <div className="absolute w-full bottom-[-9px]">
          {status === "paid" ? (
            <div className="w-full rounded-t-[24px] bg-[#ffffff] ">
              <>
                <div className="p-5">
                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p>
                      <TranslateText textKey={"amount"} namespace="pos" />
                    </p>
                    <p>{CURRENCY_FORMAT(itemPrice)}</p>
                  </div>
                  <hr />

                  {Boolean(discount) && (
                    <>
                      <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                        <p>
                          <TranslateText textKey={"discount"} namespace="pos" />
                        </p>
                        <p>{CURRENCY_FORMAT(discount)}</p>
                      </div>
                      <hr />
                    </>
                  )}

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p>
                      <TranslateText textKey={"sub_total"} namespace="pos" />
                    </p>
                    <p>{CURRENCY_FORMAT(subTotal)}</p>
                  </div>

                  <hr />

                  {currentTaxObject?.map((Obj) => {
                    if (Obj?.taxAmount) {
                      return (
                        <div
                          className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1"
                          key={Obj?.id}
                        >
                          <div className="flex gap-2">
                            <p className="font-outfit text-[14px]">
                              {Obj?.taxName || "Tax"} ({Obj?.taxPercentage}%)
                            </p>
                          </div>
                          <p className="font-outfit text-[14px]">
                            {CURRENCY_FORMAT(Obj?.taxAmount)}
                          </p>
                        </div>
                      );
                    }
                    return <></>; // To avoid undefined return
                  })}

                  <hr />

                  {Boolean(+serviceCharge) && (
                    <>
                      <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                        <p>
                          <TranslateText
                            textKey={"extra_charge"}
                            namespace="pos"
                          />
                        </p>
                        <p>{CURRENCY_FORMAT(+serviceCharge)}</p>
                      </div>
                      <hr />
                    </>
                  )}

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p className="font-semibold">
                      <TranslateText textKey={"total"} namespace="pos" />{" "}
                      <span className="bg-green-500 text-white px-2 rounded">
                        <TranslateText textKey={"paid"} namespace="pos" />
                      </span>{" "}
                    </p>
                    <p className="font-semibold">{CURRENCY_FORMAT(paid)}</p>
                  </div>

                  <div className="mt-[18px] cursor-pointer">
                    {allPayments?.filter((payment) => payment.method !== "btc")
                      ?.length ? (
                      <div
                        className="w-full flex justify-between items-center"
                        onClick={() =>
                          setPaymentModesExpanded(!paymentModesExpanded)
                        }
                      >
                        <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                          All Payments
                        </span>
                        {paymentModesExpanded ? (
                          <MobileUp />
                        ) : (
                          <FaChevronRight />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {paymentModesExpanded ? (
                      <div className="mt-[12px] flex flex-col gap-[8px]">
                        {allPayments
                          ?.filter((payment) => payment.method !== "btc")
                          ?.map((payment) => {
                            const { id, amount, method, transactionNo } =
                              payment;
                            return (
                              <div
                                key={id}
                                className="flex w-full justify-between items-center px-[12px] h-[40px] rounded-[8px]
                                 bg-[#F9FBFD] border-[1px] border-[#EDF1F7]"
                              >
                                <div className="flex items-center gap-[6px] w-full">
                                  <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                                    {paymentModeTypes?.[method]}
                                  </span>
                                  {Boolean(transactionNo) ? (
                                    <Tooltip
                                      title={
                                        transactionNo?.length > 15
                                          ? transactionNo
                                          : ""
                                      }
                                      className="font-outfit font-[500] text-[10px] text-[#222222]"
                                    >
                                      ({transactionNo?.slice(-15)})
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}

                                  {editPaymentAllowed ? (
                                    <span
                                      onClick={(e) => {
                                        dispatch(
                                          updateEditPaymentDrawer({
                                            display: true,
                                            paymentObj: payment,
                                          })
                                        );
                                        e.stopPropagation();
                                      }}
                                      className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                                    >
                                      {" "}
                                      (Edit)
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                                  {CURRENCY_FORMAT(amount)}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center mb-2  w-[100%] ">
                  {Boolean(invoicePostData?.roomId) && (
                    <div className="w-full px-5">
                      <div className="w-full h-[40px] bg-[#E5F3F3] rounded-[10px] px-[10px] flex items-center justify-between mb-[14px]">
                        <div
                          className="flex items-center gap-[6px] cursor-pointer"
                          onClick={(e) =>
                            handleBookingRedirection(
                              roomName,
                              invoicePostData,
                              e
                            )
                          }
                        >
                          <LinkFile />
                          <span className="font-outfit font-[500] text-[16px] text-[#119199] leading-[20.16px]">
                            <TranslateText
                              textKey={"bill_attached_to_room"}
                              namespace="pos"
                            />

                            {roomName}
                          </span>
                        </div>

                        {!(paid >= 0 && invoicePostData?.roomId) && (
                          <span
                            className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                            onClick={() =>
                              dispatch(updateDisplayAttachPartyDrawer(true))
                            }
                          >
                            <TranslateText textKey={"edit"} namespace="pos" />
                          </span>
                        )}

                        {deletePaymentAllowed && (
                          <GoTrash
                            className="cursor-pointer text-[#FE594E] h-[18px] w-[18px]"
                            onClick={() => setDeletePartyModal(true)}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {Boolean(invoicePostData?.partyId) && (
                    <div className="w-full px-5">
                      <div className="w-full h-[40px] bg-[#E5F3F3] rounded-[10px] px-[10px] flex items-center justify-between mb-[14px]">
                        <div
                          className="flex items-center gap-[6px] cursor-pointer"
                          onClick={(e) =>
                            handleBookingRedirection(
                              roomName,
                              invoicePostData,
                              e
                            )
                          }
                        >
                          <LinkFile />
                          <span className="font-outfit font-[500] text-[16px] text-[#119199] leading-[20.16px]">
                            {`Bill attached to ${
                              Boolean(partyName) ? partyName : ""
                            } (Party)`}
                          </span>
                        </div>

                        <span className="flex items-center gap-1">
                          <span
                            className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                            onClick={() =>
                              dispatch(updateDisplayAttachPartyDrawer(true))
                            }
                          >
                            EDIT
                          </span>

                          {deletePaymentAllowed && (
                            <GoTrash
                              className="cursor-pointer text-[#FE594E] h-[18px] w-[18px]"
                              onClick={() => setDeletePartyModal(true)}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className=" w-[100%] flex flex-col gap-2 justify-center items-center ">
                    <div className="flex gap-2 items-center">
                      {" "}
                      <button
                        className="font-outfit  font-[500] text-[14px] leading-[18px] rounded-[8px] p-2 '
                     bg-[#EAF2FD] text-[#007FFF] text-center "
                        onClick={printInvoicePDF}
                        onMouseEnter={() => setPrintInvoice(true)}
                        onMouseLeave={() => setPrintInvoice(false)}
                      >
                        <TranslateText textKey={"print"} namespace="pos" />
                      </button>
                    </div>

                    <button
                      className="font-outfit font-[600] text-[18px] leading-[18px] w-[100%] 
                      bg-[#007FFF] py-3 text-[#FFF] "
                      onClick={() =>
                        dispatch(updateDisplayTakePaymentDrawer(true))
                      }
                    >
                      TAKE PAYMENT
                    </button>
                  </div>
                </div>
              </>
            </div>
          ) : status === "billed" ? (
            <div className=" rounded-t-[24px] bg-[#ffffff] ">
              <>
                <div className="p-5">
                  {itemPrice ? (
                    <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                      <p>
                        <TranslateText textKey={"amount"} namespace="pos" />
                      </p>
                      <p>{CURRENCY_FORMAT(itemPrice)}</p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <hr />

                  {discount ? (
                    <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                      <p>
                        <TranslateText textKey={"discount"} namespace="pos" />
                      </p>
                      <p>{CURRENCY_FORMAT(discount)}</p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <hr />

                  {discount ? (
                    <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                      <p className="font-semibold">
                        <TranslateText textKey={"sub_total"} namespace="pos" />
                      </p>
                      <p className="font-semibold">
                        {CURRENCY_FORMAT(subTotal)}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <hr />

                  {currentTaxObject?.map((Obj) => {
                    if (Obj?.taxAmount) {
                      return (
                        <div
                          className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1"
                          key={Obj?.id}
                        >
                          <div className="flex gap-2">
                            <p className="font-outfit text-[14px]">
                              {Obj?.taxName || "Tax"} ({Obj?.taxPercentage}%)
                            </p>
                          </div>
                          <p className="font-outfit text-[14px]">
                            {CURRENCY_FORMAT(Obj?.taxAmount)}
                          </p>
                        </div>
                      );
                    }
                  })}

                  <hr />

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p>
                      <TranslateText textKey={"extra_charge"} namespace="pos" />

                      <span
                        onClick={() => setIsServiceCharge(!isServiceCharge)}
                        className="text-blue-600 text-xs cursor-pointer"
                      >
                        {isServiceCharge ? (
                          <span>
                            (<TranslateText textKey={"hide"} namespace="pos" />)
                          </span>
                        ) : (
                          <span className="ml-[2px]">
                            {Boolean(serviceCharge) ? (
                              <>
                                (
                                <TranslateText
                                  textKey={"edit"}
                                  namespace="pos"
                                />
                                )
                              </>
                            ) : (
                              <>
                                (
                                <TranslateText
                                  textKey={"add"}
                                  namespace="pos"
                                />
                                )
                              </>
                            )}
                          </span>
                        )}
                      </span>
                    </p>
                    <p>{CURRENCY_FORMAT(serviceCharge)}</p>
                  </div>

                  <hr />

                  {isServiceCharge && (
                    <div className="shadow p-3 mt-2">
                      <div>
                        <div class="mb-4">
                          <h1 className="block text-gray-700 text-sm font-bold mb-2">
                            {Boolean(serviceCharge) ? "Edit" : "Add"}
                            <TranslateText
                              textKey={"service_charge"}
                              namespace="pos"
                            />
                          </h1>

                          <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            value={serviceChargeAmount}
                            type="number"
                            placeholder="Enter here"
                            onChange={(e) =>
                              setServiceChargeAmount(e.target.value)
                            }
                          />
                          <div className="mt-3 flex justify-between">
                            <div />

                            {Boolean(serviceChargeAmount) ? (
                              <Button
                                onClick={() =>
                                  handleServiceCharge(
                                    serviceChargeAmount,
                                    setIsServiceCharge
                                  )
                                }
                              >
                                <TranslateText
                                  textKey={"apply"}
                                  namespace="pos"
                                />
                              </Button>
                            ) : (
                              <Button disabled={true}>
                                <TranslateText
                                  textKey={"apply"}
                                  namespace="pos"
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <div className="flex gap-2">
                      <p className="font-bold text-1xl">
                        <TranslateText textKey={"total"} namespace="pos" />
                      </p>
                    </div>
                    <p className="font-bold text-1xl">
                      {CURRENCY_FORMAT(total)}
                    </p>
                  </div>

                  <hr />

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <div className="flex gap-2">
                      <p className="text-sm text-slate-400 ">
                        <TranslateText textKey={"paid"} namespace="pos" />
                      </p>
                    </div>
                    <p className="text-sm text-slate-400">
                      {Boolean(paid) ? CURRENCY_FORMAT(paid) : 0}
                    </p>
                  </div>

                  <hr />

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <div className="flex gap-2">
                      <p className="text-sm font-400 text-[#Ff0000]">
                        <TranslateText textKey={"due"} namespace="pos" />
                      </p>
                    </div>
                    <p className="text-sm font-400 text-[#Ff0000]">
                      {Boolean(paid)
                        ? CURRENCY_FORMAT(total - paid)
                        : CURRENCY_FORMAT(total)}
                    </p>
                  </div>
                </div>

                <div className="mt-[0] px-3 cursor-pointer">
                  {allPayments?.filter((payment) => payment.method !== "btc")
                    ?.length ? (
                    <div
                      className="w-full flex justify-between items-center"
                      onClick={() =>
                        setPaymentModesExpanded(!paymentModesExpanded)
                      }
                    >
                      <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                        All Payments
                      </span>
                      {paymentModesExpanded ? <MobileUp /> : <FaChevronRight />}
                    </div>
                  ) : (
                    ""
                  )}
                  {paymentModesExpanded ? (
                    <div className="mt-[12px] mb-[6px] flex flex-col gap-[8px]">
                      {allPayments
                        ?.filter((payment) => payment.method !== "btc")
                        ?.map((payment) => {
                          const { id, amount, method, transactionNo } = payment;
                          return (
                            <div
                              key={id}
                              className="flex w-full justify-between items-center px-[12px] h-[40px] rounded-[8px]
                                 bg-[#F9FBFD] border-[1px] border-[#EDF1F7]"
                            >
                              <div className="flex items-center gap-[6px] w-full">
                                <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                                  {paymentModeTypes?.[method]}
                                </span>
                                {Boolean(transactionNo) ? (
                                  <Tooltip
                                    title={
                                      transactionNo?.length > 15
                                        ? transactionNo
                                        : ""
                                    }
                                    className="font-outfit font-[500] text-[10px] text-[#222222]"
                                  >
                                    ({transactionNo?.slice(-15)})
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}

                                {editPaymentAllowed ? (
                                  <span
                                    onClick={(e) => {
                                      dispatch(
                                        updateEditPaymentDrawer({
                                          display: true,
                                          paymentObj: payment,
                                        })
                                      );
                                      e.stopPropagation();
                                    }}
                                    className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                                  >
                                    {" "}
                                    (Edit)
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                                {CURRENCY_FORMAT(amount)}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className="flex  flex-col items-center gap-2 pt-2 w-full bg-[#FFF] mb-2 border-[0.5px] 
                  border-t-[#00000026] rounded-t-[10px] "
                >
                  <div className="flex gap-2 w-full justify-center">
                    <div
                      className="text-[12px] flex items-center gap-2 text-[#808080] font-[400] font-[Poppins]
                   bg-[#526EFF0D] px-3 py-2 rounded-md cursor-pointer leading-[20px]"
                      onClick={handleUndoBilled}
                    >
                      <FaUndo
                        color="#808080"
                        className="font-[300] text-[11px]"
                      />
                      <p> Undo</p>
                    </div>

                    <Button
                      className="text-[0.875rem] border-0  text-[#007FFF] font-[500] font-[Poppins] bg-[#526EFF0D] px-3 py-2 rounded-md cursor-pointer"
                      onClick={printInvoicePDF}
                      onMouseEnter={() => setPrintInvoice(true)}
                      onMouseLeave={() => setPrintInvoice(false)}
                    >
                      <TranslateText textKey={"print"} namespace="pos" />
                    </Button>
                  </div>

                  {tableOrderDetails?.bookingId ? (
                    <></>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <Button
                        className="text-[0.875rem] border-0  text-[#007FFF] font-[500] font-[Poppins] bg-[#526EFF0D] px-3 py-2 rounded-md cursor-pointer"
                        onClick={() =>
                          dispatch(updateDisplayAttachPartyDrawer(true))
                        }
                      >
                        <TranslateText
                          textKey={"attach_to_room_party"}
                          namespace="pos"
                        />
                      </Button>

                      <Button
                        className="text-[0.875rem] border-0  text-[#007FFF] font-[500] font-[Poppins] bg-[#526EFF0D] px-3 py-2 rounded-md cursor-pointer"
                        onClick={() =>
                          dispatch(updateDisplayDiscountDrawer(true))
                        }
                      >
                        <TranslateText
                          textKey={"apply_discount"}
                          namespace="pos"
                        />
                      </Button>
                    </div>
                  )}

                  <div className=" w-[100%] ">
                    <button
                      className="font-[Poppins] font-[600] text-[18px] leading-[18px] w-[50%] bg-[#EAF2FD] py-3 text-[#007FFF] "
                      onClick={() =>
                        dispatch(updateDisplayTakePaymentDrawer(true))
                      }
                    >
                      <TranslateText textKey={"take_payment"} namespace="pos" />
                    </button>

                    {total <= 0 ? (
                      <button
                        className="font-[Poppins] font-[600] text-[18px] leading-[18px] w-[50%] text-[#FFF] py-3  bg-[#007FFF] "
                        onClick={() => handleCloseOrderDueZero()}
                      >
                        <TranslateText
                          textKey={"close_order"}
                          namespace="pos"
                        />
                      </button>
                    ) : Boolean(
                        invoicePostData?.roomId || invoicePostData?.partyId
                      ) ? (
                      <button
                        className="font-[Poppins] font-[600] text-[18px] leading-[18px] w-[50%] text-[#FFF] py-3  bg-[#007FFF] "
                        onClick={() => handleAttahedCloseOrder()}
                      >
                        <TranslateText
                          textKey={"close_order"}
                          namespace="pos"
                        />
                      </button>
                    ) : (
                      <button
                        className="font-[Poppins] font-[600] text-[18px] leading-[18px] w-[50%] text-[#FFF] py-3  bg-[#007FFF] "
                        onClick={() =>
                          dispatch(updateDisplayCloseOrderDrawer(true))
                        }
                      >
                        <TranslateText
                          textKey={"close_order"}
                          namespace="pos"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </>
            </div>
          ) : status === "due" ? (
            <div className=" rounded-t-[24px] bg-[#ffffff] ">
              <>
                <div className="p-5">
                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p>
                      <TranslateText textKey={"amount"} namespace="pos" />
                    </p>
                    <p>{CURRENCY_FORMAT(itemPrice)}</p>
                  </div>
                  <hr />

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p>
                      <TranslateText textKey={"discount"} namespace="pos" />
                    </p>
                    <p>{CURRENCY_FORMAT(discount)}</p>
                  </div>
                  <hr />

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p className="font-semibold">
                      <TranslateText textKey={"sub_total"} namespace="pos" />
                    </p>
                    <p className="font-semibold">{CURRENCY_FORMAT(subTotal)}</p>
                  </div>

                  <hr />

                  {currentTaxObject?.map((Obj) => {
                    if (Obj?.taxAmount) {
                      return (
                        <div
                          className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1"
                          key={Obj?.id}
                        >
                          <div className="flex gap-2">
                            <p className="font-outfit text-[14px]">
                              {Obj?.taxName || "Tax"} ({Obj?.taxPercentage}%)
                            </p>
                          </div>
                          <p className="font-outfit text-[14px]">
                            {CURRENCY_FORMAT(Obj?.taxAmount)}
                          </p>
                        </div>
                      );
                    }
                    return <></>; // To avoid undefined return
                  })}

                  <hr />

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <p>
                      <TranslateText textKey={"extra_charge"} namespace="pos" />

                      <span
                        onClick={() => setIsServiceCharge(!isServiceCharge)}
                        className="text-blue-600 text-xs cursor-pointer"
                      >
                        {isServiceCharge ? (
                          <span>
                            (<TranslateText textKey={"hide"} namespace="pos" />)
                          </span>
                        ) : (
                          <span className="ml-[2px]">
                            {Boolean(serviceCharge) ? (
                              <>
                                (
                                <TranslateText
                                  textKey={"edit"}
                                  namespace="pos"
                                />
                                )
                              </>
                            ) : (
                              <>
                                (
                                <TranslateText
                                  textKey={"add"}
                                  namespace="pos"
                                />
                                )
                              </>
                            )}
                          </span>
                        )}
                      </span>
                    </p>
                    <p>{CURRENCY_FORMAT(serviceCharge)}</p>
                  </div>
                  <hr />

                  {isServiceCharge && (
                    <div className="shadow p-3 mt-2">
                      <div>
                        <div class="mb-4">
                          <h1 className="block text-gray-700 text-sm font-bold mb-2">
                            {Boolean(serviceCharge) ? "Edit" : "Add"}
                            <TranslateText
                              textKey={"service_charge"}
                              namespace="pos"
                            />
                          </h1>

                          <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            value={serviceChargeAmount}
                            type="number"
                            placeholder="Enter here"
                            onChange={(e) =>
                              setServiceChargeAmount(e.target.value)
                            }
                          />
                          <div className="mt-3 flex justify-between">
                            <div />

                            {Boolean(serviceChargeAmount) ? (
                              <Button onClick={handleServiceCharge}>
                                <TranslateText
                                  textKey={"apply"}
                                  namespace="common"
                                />
                              </Button>
                            ) : (
                              <Button disabled={true}>
                                <TranslateText
                                  textKey={"apply"}
                                  namespace="common"
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex justify-between text-sm text-slate-400 mt-2 my-1 px-1">
                    <div className="flex gap-2">
                      <p className="font-bold text-1xl">
                        <TranslateText textKey={"total"} namespace="pos" />
                      </p>
                      {status === "due" && (
                        <span className="bg-[#F36960] text-white px-2 rounded">
                          <TranslateText textKey={"due"} namespace="home" />
                        </span>
                      )}
                    </div>
                    <p className="font-bold text-1xl">
                      {status === "due"
                        ? Boolean(paid)
                          ? CURRENCY_FORMAT(total - paid)
                          : CURRENCY_FORMAT(total)
                        : CURRENCY_FORMAT(total)}
                    </p>
                  </div>
                </div>

                <div className="mt-[10px] cursor-pointer px-4">
                  {allPayments?.filter((payment) => payment.method !== "btc")
                    ?.length ? (
                    <div
                      className="w-full flex justify-between items-center"
                      onClick={() =>
                        setPaymentModesExpanded(!paymentModesExpanded)
                      }
                    >
                      <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                        All Payments
                      </span>
                      {paymentModesExpanded ? <MobileUp /> : <FaChevronRight />}
                    </div>
                  ) : (
                    ""
                  )}
                  {paymentModesExpanded ? (
                    <div className="mt-[12px] flex flex-col gap-[8px]">
                      {allPayments
                        ?.filter((payment) => payment.method !== "btc")
                        ?.map((payment) => {
                          const { id, amount, method, transactionNo } = payment;
                          return (
                            <div
                              key={id}
                              className="flex w-full justify-between items-center px-[12px] h-[40px] rounded-[8px]
                                 bg-[#F9FBFD] border-[1px] border-[#EDF1F7]"
                            >
                              <div className="flex items-center gap-[6px] w-full">
                                <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                                  {paymentModeTypes?.[method]}
                                </span>
                                {Boolean(transactionNo) ? (
                                  <Tooltip
                                    title={
                                      transactionNo?.length > 15
                                        ? transactionNo
                                        : ""
                                    }
                                    className="font-outfit font-[500] text-[10px] text-[#222222]"
                                  >
                                    ({transactionNo?.slice(-15)})
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}

                                {editPaymentAllowed ? (
                                  <span
                                    onClick={(e) => {
                                      dispatch(
                                        updateEditPaymentDrawer({
                                          display: true,
                                          paymentObj: payment,
                                        })
                                      );
                                      e.stopPropagation();
                                    }}
                                    className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                                  >
                                    {" "}
                                    (Edit)
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <span className="font-outfit font-[500] text-[14px] leading-[24px] text-[#222222]">
                                {CURRENCY_FORMAT(amount)}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className="flex  flex-col items-center gap-2 pt-2 w-full bg-[#FFF] mb-2 
                  "
                >
                  {Boolean(invoicePostData?.roomId) && (
                    <div className="w-full px-5">
                      <div className="w-full h-[40px] bg-[#E5F3F3] rounded-[10px] px-[10px] flex items-center justify-between mb-[14px]">
                        <div
                          className="flex items-center gap-[6px] cursor-pointer"
                          onClick={(e) =>
                            handleBookingRedirection(
                              roomName,
                              invoicePostData,
                              e
                            )
                          }
                        >
                          <LinkFile />
                          <span className="font-outfit font-[500] text-[16px] text-[#119199] leading-[20.16px]">
                            <TranslateText
                              textKey={"bill_attached_to_room"}
                              namespace="pos"
                            />{" "}
                            {roomName}
                          </span>
                        </div>

                        {paid > 0 ? (
                          <></>
                        ) : (
                          <span className="flex items-center gap-1">
                            <span
                              className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                              onClick={() =>
                                dispatch(updateDisplayAttachPartyDrawer(true))
                              }
                            >
                              <TranslateText
                                textKey={"edit"}
                                namespace="common"
                              />
                            </span>

                            {deletePaymentAllowed && (
                              <GoTrash
                                className="cursor-pointer text-[#FE594E] h-[18px] w-[18px]"
                                onClick={() => setDeletePartyModal(true)}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {Boolean(invoicePostData?.partyId) && (
                    <div className="w-full px-5">
                      <div className="w-full h-[40px] bg-[#E5F3F3] rounded-[10px] px-[10px] flex items-center justify-between mb-[14px]">
                        <div
                          className="flex items-center gap-[6px] cursor-pointer"
                          onClick={(e) =>
                            handleBookingRedirection(
                              roomName,
                              invoicePostData,
                              e
                            )
                          }
                        >
                          <LinkFile />
                          <span className="font-outfit font-[500] text-[16px] text-[#119199] leading-[20.16px]">
                            {`Bill attached to ${
                              Boolean(partyName) ? partyName : ""
                            } (Party)`}
                          </span>
                        </div>
                        <span
                          className="font-outfit font-[500] text-[12px] leading-[24px] text-[#007FFF] cursor-pointer"
                          onClick={() =>
                            dispatch(updateDisplayAttachPartyDrawer(true))
                          }
                        >
                          <TranslateText textKey={"edit"} namespace="common" />
                        </span>
                      </div>
                    </div>
                  )}

                  {invoicePostData?.roomId || invoicePostData?.partyId ? (
                    <div className=" w-[100%] flex flex-col gap-2 justify-center items-center ">
                      <button
                        className="font-outfit w-[100%]  font-[600] text-[14px] leading-[18px]  p-2 '
                     bg-[#EAF2FD] text-[#007FFF] text-center "
                        onClick={printInvoicePDF}
                        onMouseEnter={() => setPrintInvoice(true)}
                        onMouseLeave={() => setPrintInvoice(false)}
                      >
                        <TranslateText textKey={"print"} namespace="pos" />
                      </button>
                    </div>
                  ) : (
                    <div className=" w-[100%] flex flex-col gap-2 justify-center items-center ">
                      <button
                        className="font-outfit  font-[500] text-[14px] leading-[18px] rounded-[8px] p-2 '
                     bg-[#EAF2FD] text-[#007FFF] text-center "
                        onClick={printInvoicePDF}
                        onMouseEnter={() => setPrintInvoice(true)}
                        onMouseLeave={() => setPrintInvoice(false)}
                      >
                        <TranslateText textKey={"print"} namespace="pos" />
                      </button>

                      {/* <button
                        className="font-outfit font-[600] text-[18px] leading-[18px] w-[100%] 
                      bg-[#007FFF] py-3 text-[#FFF] "
                        onClick={() =>
                          dispatch(updateDisplayTakePaymentDrawer(true))
                        }
                      >
                        TAKE PAYMENT
                      </button> */}

                      <button
                        className="font-[Poppins] font-[600] text-[18px] leading-[18px] w-[100%] text-[#FFF] py-3  bg-[#007FFF] "
                        onClick={() =>
                          dispatch(updateDisplayCloseOrderDrawer(true))
                        }
                      >
                        <TranslateText
                          textKey={"close_order"}
                          namespace="pos"
                        />
                      </button>
                    </div>
                  )}
                </div>
              </>
            </div>
          ) : !Boolean(status) || status === "inProgress" ? (
            <>
              {orderLoading ? (
                <div className="flex justify-center items-center p-3">
                  <CircularProgress size={24} />
                </div>
              ) : (
                <div className="w-full  rounded-t-[24px] bg-[#ffffff] shadow-mobile-footer ">
                  <div className="flex justify-between p-3">
                    <TranslateText textKey={"amount"} namespace="pos" />

                    <span> {CURRENCY_FORMAT(itemTotalAmount)}</span>
                  </div>

                  {activePosType != POS_TYPE[3] ? (
                    <>
                      {invoicePostLoading ? (
                        <div className="flex justify-center items-center p-3">
                          <CircularProgress size={18} />
                        </div>
                      ) : (
                        <>
                          {order?.length ? (
                            <div className="flex justify-center items-center gap-3 w-full mb-2  bg-[#FFF]">
                              <Button
                                type="primary"
                                onClick={() => handleKOT("inProgress")}
                                className=" text-[16px] w-[40%]"
                                onMouseEnter={() => setPrintKOT(true)}
                                onMouseLeave={() => setPrintKOT(false)}
                              >
                                {activePosType === POS_TYPE[0]
                                  ? "KOT"
                                  : "New Order"}
                              </Button>

                              <Button
                                onClick={() => handleKOT("held")}
                                className="text-[16px] w-[40%] border-[1px] border-[#007FFF] text-[#007FFF]"
                              >
                                <TranslateText
                                  textKey={"hold"}
                                  namespace="pos"
                                />
                              </Button>

                              <Button
                                type="primary"
                                onClick={createBill}
                                className=" text-[16px] w-[40%]"
                              >
                                <TranslateText
                                  textKey={"bill"}
                                  namespace="pos"
                                />
                              </Button>
                            </div>
                          ) : (
                            <div className="flex justify-center items-center gap-3 w-full mb-2  bg-[#FFF]">
                              <Button
                                type="primary"
                                onClick={() => handleKOT("inProgress")}
                                className=" text-[16px] w-[40%]"
                                onMouseEnter={() => setPrintKOT(true)}
                                onMouseLeave={() => setPrintKOT(false)}
                              >
                                {activePosType === POS_TYPE[0]
                                  ? "KOT"
                                  : "New Order"}
                              </Button>

                              <Button
                                onClick={() => handleKOT("held")}
                                className="text-[16px] w-[40%] border-[1px] border-[#007FFF] text-[#007FFF]"
                              >
                                <TranslateText
                                  textKey={"hold"}
                                  namespace="pos"
                                />
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="flex w-full mb-2 border rounded-xl border-[#2F80ED]">
                      {invoicePostLoading ? (
                        <CircularProgress size={18} />
                      ) : (
                        <Button
                          onClick={() => handleKOT("inProgress", true)}
                          icon={<Bill />}
                          className="flex items-center justify-center flex-1 border-none text-[#2F80ED] text-[16px]"
                        >
                          <span className="hover:bg-primary text-white w-full rounded bg-blue-400">
                            {" "}
                            <TranslateText textKey={"bill"} namespace="pos" />
                          </span>
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <></>
          )}

          <DeleteOrderModal
            deletePopUp={deletePopUp}
            setDeletePopUp={setDeletePopUp}
          />

          {printKOT && (
            <GenerateKOT
              print={printKOT}
              val={componentRef}
              tableOrderDetails={tableOrderDetails}
              order={latestOrder}
              latestOrder={latestOrder}
            />
          )}

          {printInvoice && (
            <GenerateInvoice
              print={printInvoice}
              val={componentInvoiceRef}
              tableOrderDetails={tableOrderDetails}
              customer={customer}
            />
          )}

          <AttachRoomPartyDrawer
            total={total}
            invoice={invoicePostData}
            setIsCloseOrder={setIsCloseOrder}
            tableOrderDetails={tableOrderDetails}
            hotelId={hotelDetails?.id}
          />
          <CloseOrderDrawer
            total={Boolean(paid) ? total - paid : total}
            invoice={invoicePostData}
            setIsCloseOrder={setIsCloseOrder}
            tableOrderDetails={tableOrderDetails}
            hotelId={hotelDetails?.id}
          />
          <TakePaymentDrawer
            total={Boolean(paid) ? total - paid : total}
            invoice={invoicePostData}
            setIsCloseOrder={setIsCloseOrder}
            tableOrderDetails={tableOrderDetails}
            hotelId={hotelDetails?.id}
          />
          <ApplyDiscountDrawer
            itemPrice={itemPrice}
            discountInfo={invoicePostData?.discountInfo}
          />

          <EditPaymentDrawer
            isBookingListDrawer={false}
            drawerWidth={menuDrawer ? "33%" : "30%"}
            isPosComponent={true}
          />

          <Modal
            open={deletePartyModal}
            centered
            title=""
            width={"auto"}
            styles={{ mask: { backdropFilter: "blur(2px)" } }}
            onCancel={() => setDeletePartyModal(false)}
            closable={false} // Hides the close (cancel) icon
            footer={
              <div className="w-full flex gap-2 justify-center mt-5">
                <Button
                  className="text-[1.1rem]  w-[11rem] h-[3rem] border-[#D0D5DD] text-[#808080] rounded-[0.5rem] font-outfit font-[600] "
                  onClick={() => setDeletePartyModal(false)}
                >
                  NO
                </Button>
                <Button
                  className="text-[#FFF] text-[1.1rem] rounded-xl  bg-[#007FFF] w-[11rem] h-[3rem]"
                  onClick={() =>
                    deletePartyAndRoomFromPosInvoice(
                      invoicePostData,
                      allPayments,
                      setDeletePartyModal
                    )
                  }
                >
                  {false ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <div>YES</div>
                  )}
                </Button>
              </div>
            }
          >
            <div className="text-[#222B45] font-outfit font-[500] text-[18px]  ">
              Are you sure you want to remove Party/Booking from this Invoice?
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SideContainer;
